import banner from './../../../../assets/images/study.gif';
import * as React from 'react';
import { PlayerController } from '../../PlayerController';

class Classical extends React.Component {
  componentDidMount() {
    //set initial volume on range input
    (document.getElementById("volumeRangeInput") as HTMLInputElement).value = this.getVolumeOfClassical();
  }

  changeVol(element: any) {
    (document.getElementById("volumeRangeInput") as HTMLInputElement).value = element.target.value;
    (document.getElementById("volumePercent") as HTMLInputElement).textContent = element.target.value+"%";
    PlayerController.changeVolumeTo("classical", element.target.value);
  }

  getVolumeOfClassical() {
    return PlayerController.getCurrentVolumeOfPlayer("classical");
  }

  render() {
    return(
      <div>
        <img className="h-36 w-full object-cover rounded-lg" alt="banner" src={banner}/>
        <div className="flex items-center my-4">
          <label htmlFor="range" className="font-bold text-gray-600 mr-4 inline-block dark:text-slate-400">Volume</label>
          <input id="volumeRangeInput" onChange={(e) => this.changeVol(e) } type="range" min="0" name="price" max="100" className="rounded-lg inline-block w-96 h-2 accent-indigo-500 bg-blue-100 appearance-none" />
          <div className="inline-block ml-8">
            <label htmlFor="range" id="volumePercent" className="font-bold z-10 text-gray-600 text-right dark:text-slate-400">{this.getVolumeOfClassical()}%</label>
          </div>
        </div>
        <div className="text-gray-300 text-sm">
          <p>Some music may take a little longer to load. You might have to wait for a short moment when changing the volume until sound starts playing.</p>
        </div>  
      </div>
    );
  }
}

export default Classical;
