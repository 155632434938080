import * as React from 'react';
import './SidebarElement.module.css';
import { Link } from "react-router-dom";

interface SidebarElementProps {
  emoji: String,
  Name: String,
  link: String
}

class SidebarElement extends React.Component<SidebarElementProps> {  
  render() {
    return(
      <div className="rounded-xl hover:bg-slate-200 dark:hover:bg-slate-700 transition ease-in-out duration-300 my-1 mr-2">
        <Link to={`/${this.props.link}`}>
          <button className="p-1 pl-2 font-bold text-base block text-gray-800 dark:text-slate-200"><span className="mr-1">{this.props.emoji}</span> <span>{this.props.Name}</span></button>
        </Link>
      </div>
    );
  }
}

export default SidebarElement;
