import * as React from 'react';
import './Sidebar.module.css';
import SidebarElement from '../sidebar-element/SidebarElement';
import toast, { Toaster } from 'react-hot-toast';

class Sidebar extends React.Component {
    underConstructionToast() {
        toast('This feature is coming soon!', {icon: '🚧', style: { fontWeight: 700 }});
    }

    render() {
        return(
        <div className="scrollbar scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-white overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full overflow-auto">
            <ul>
                <li>
                    <p className="font-semibold text-sm text-slate-400 tracking-wider dark:text-slate-400">Music</p>
                </li>
                <li>
                    <SidebarElement link="lofi" emoji="🎶" Name="Lofi"></SidebarElement>
                </li>
                <li>
                    <SidebarElement link="jazz" emoji="🎷" Name="Jazz"></SidebarElement>
                </li>
                <li>
                    <SidebarElement link="classical" emoji="🎹" Name="Classical"></SidebarElement>
                </li>
                <li>
                    <p className="font-semibold text-sm text-slate-400 tracking-wider dark:text-slate-400">Ambiance</p>
                </li> 
                <li>
                    <SidebarElement link="rain" emoji="🌧️" Name="Rain"></SidebarElement>
                </li>
                <li>
                    <SidebarElement link="thunder" emoji="⚡" Name="Thunder"></SidebarElement>
                </li>
                <li>
                    <SidebarElement link="fireplace" emoji="🔥" Name="Fireplace"></SidebarElement>
                </li>
                <li>
                    <SidebarElement link="cafe" emoji="☕" Name="Café"></SidebarElement>
                </li>
                <li>
                    <SidebarElement link="nature" emoji="🐦" Name="Nature"></SidebarElement>
                </li>
                <li>
                    <p className="font-semibold text-sm text-slate-400 tracking-wider dark:text-slate-400">Tools</p>
                </li>
                <li>
                    <div className="cursor-pointer rounded-xl hover:bg-slate-200 transition ease-in-out duration-300 my-1 mr-2">
                        <button onClick={()=>this.underConstructionToast()}>
                            <button className="p-1 pl-2 font-bold text-base block text-gray-800 dark:text-slate-200"><span className="mr-1">📝</span> <span>Todo List</span></button>
                        </button>
                    </div>
                </li>
                <li>
                    <div className="cursor-pointer rounded-xl hover:bg-slate-200 transition ease-in-out duration-300 my-1 mr-2">
                        <button onClick={()=>this.underConstructionToast()}>
                            <button className="p-1 pl-2 font-bold text-base block text-gray-800 dark:text-slate-200"><span className="mr-1">⏱️</span> <span>Timer</span></button>
                        </button>
                    </div>
                </li>
            </ul>
            <Toaster />
        </div>
        );
    }
}

export default Sidebar;
