import * as React from 'react';
import './Home.module.css';
import Sidebar from '../sidebar/Sidebar';
import { Outlet } from 'react-router-dom';

interface HomeProps {
};

interface HomeState {
  curTime: Date
}

class Home extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);
    this.state = {
        curTime: new Date()
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({
        curTime : new Date()
      })
    }, 1000)

    var toggler = document.getElementById('default-toggle') as HTMLInputElement
    if (localStorage.getItem('color-theme') === 'dark') {
      toggler.checked = true
    } else {
      toggler.checked = false
    }
  }

  getFormattedDateInfo(date: Date) {
    var dayOfMonth = date.getDate().toString()
    if (Number(dayOfMonth) === 1 || dayOfMonth[-1] === "1") {
      dayOfMonth += "st"
    } else if(Number(dayOfMonth) === 2 || dayOfMonth[-1] === "2") {
      dayOfMonth+= "nd"
    } else if(Number(dayOfMonth) === 3 || dayOfMonth[-1] === "3") {
      dayOfMonth += "rd"
    } else {
      dayOfMonth += "th"
    }

    return `${date.toLocaleDateString('en-EN', { weekday: 'long' })}, ${dayOfMonth} ${date.toLocaleString('en-EN', { month: 'long' })} ${date.getFullYear().toString()}`
  }

  toggleDarkmode() {
    var toggler = document.getElementById('default-toggle') as HTMLInputElement
    // if set via local storage previously
    if (localStorage.getItem('color-theme')) {
      if (localStorage.getItem('color-theme') === 'light') {
          document.documentElement.classList.add('dark');
          localStorage.setItem('color-theme', 'dark');
          toggler.checked = true;
      } else {
          document.documentElement.classList.remove('dark');
          localStorage.setItem('color-theme', 'light');
          toggler.checked = false;
      }

    // if NOT set via local storage previously
    } else {
        if (document.documentElement.classList.contains('dark')) {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('color-theme', 'light');
            toggler.checked = true;
        } else {
            document.documentElement.classList.add('dark');
            localStorage.setItem('color-theme', 'dark');
            toggler.checked = false;
        }
    }
  }

  render() {
    return(
      <div>
          <div className="flex-auto max-w-4xl mx-auto">
            <div className="flex">
              <div className="flex-1 w-64">
                <div className="dark:text-slate-200 font-medium mb-2 mx-auto">
                  <span className='dark:text-slate-200 font-bold'>{ this.state.curTime.toLocaleTimeString().replace("/.*({2}:{2}:{2}).*/", "$1") }</span><br/>
                  <span className='text-gray-400'>{this.getFormattedDateInfo(this.state.curTime)}</span>
                </div>
              </div>
              <div className="flex-1 w-32">
                <div className='flex py-4 relative'>
                  <label htmlFor="default-toggle" className="cursor-pointer absolute inline-block right-0">
                    <label className="absolute inline-block right-0 text-sm font-medium text-gray-400 dark:text-gray-200 mr-14">Darkmode</label>
                    <input onClick={() => this.toggleDarkmode()} type="checkbox" value="" id="default-toggle" className="sr-only peer"/>
                    <div className="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-indigo-500"></div>
                  </label>
                </div>
              </div>
            </div>
            <div className="shadow-2xl bg-gray-100 border border-slate-200 dark:border-slate-700 rounded-3xl flex-auto max-w-4xl mx-auto dark:bg-slate-800 transition">
              <div className="flex m-8">
                <div className="flex-none basis-1/3 border-r border-slate-200 dark:border-slate-700">
                  <Sidebar></Sidebar>
                </div>
                <div className="flex-auto basis-2/3 pl-6">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default Home;
