import sparkles from '../../assets/images/sparkles.gif';
import * as React from 'react';
import './Home.module.css';
import { PlayerController } from '../sounds/PlayerController';

class Default extends React.Component {
  test() {
    PlayerController.play("lofi");
  }

  render() {
    return(
        <div>
            <h1 className="text-2xl font-black centered text-center text-slate-700 m-4 dark:text-slate-400">What is lofi.study?</h1>
            <div className="mt-12">
              <p className="text-lg text-slate-400 font-bold dark:text-slate-200">
                This is a place of focus and mindfulness. We often struggle with staying productive when studying or working. 
                Calming music and nature ambient sound like rain or thunder can help you focusing on intense tasks.
              </p>
            </div>
            <div className="place-items-center grid mt-12">
                <img className="h-24" src={sparkles} alt="sparkles" />
            </div>
        </div>
    );
  }
}

export default Default;
