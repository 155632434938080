import React from 'react';
import YouTube from 'react-youtube';

interface PlayerProps {
    name: string,
    videoId: string,
    ref: any
};

interface PlayerState {
    player: any,
    volume: number,
    playerLoaded: boolean
}

class Player extends React.Component<PlayerProps, PlayerState> {
    constructor(props: PlayerProps) {
        super(props);
        this.state = {
            player: null,
            volume: 0,
            playerLoaded: false
        };

        this.setVolTo = this.setVolTo.bind(this);
        this.onReady = this.onReady.bind(this);
    }

    onReady = (event: any) => {
        this.setState({ player: event.target }, () => {
            this.setState({ playerLoaded: true });
        });
        event.target.setVolume(this.state.volume);
        event.target.playVideo();
    };

    setVolTo(state: PlayerState, vol: number) {
        state.player.setVolume(vol);
        state.volume = vol;
    }

    changeVol(state: PlayerState, element: any) {
        state.player.setVolume(element.target.value);
        this.setState({ volume: element.target.value });
    }
    
    swagballs(state: PlayerState) {
        state.player.pauseVideo();
    }

    render() {
        return(
        <div>
            <div style={{ display: "none" }}>
                <YouTube videoId={this.props.videoId} onReady={(e) => this.onReady(e)} />
            </div>
        </div>
        );
    }
}

export default Player;