import Home from './component/home/Home';
import Rain from './component/sounds/details/rain/Rain';
import './App.css';
import { Helmet } from 'react-helmet';
import { Routes, Route } from "react-router-dom";
import { PlayerController } from './component/sounds/PlayerController';
import {MobileView,BrowserView} from 'react-device-detect';
import Lofi from './component/sounds/details/lofi/Lofi';
import Default from './component/home/Default';
import Jazz from './component/sounds/details/jazz/Jazz';
import Classical from './component/sounds/details/classical/Classical';
import Thunder from './component/sounds/details/thunder/Thunder';
import Fireplace from './component/sounds/details/fireplace/Fireplace';
import Cafe from './component/sounds/details/cafe/Cafe';
import Nature from './component/sounds/details/nature/Nature';

function App() {
  function initScripts() {
    // It's best to inline this in `head` to avoid FOUC (flash of unstyled content) when changing pages or themes
    if (
      localStorage.getItem('color-theme') === 'dark' ||
      (!('color-theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }

  return (
    <div>
      <BrowserView>
        <Helmet>
          <title>lofi.study — Your focus sanctuary.</title>
          <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
          { initScripts() }
        </Helmet>
        {PlayerController.getPlayersForDOM()}
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path='*' element={<Default />} />
            <Route path="/" element={<Default />} />
            <Route path="lofi" element={<Lofi />} />
            <Route path="jazz" element={<Jazz/>} />
            <Route path="classical" element={<Classical/>} />
            <Route path="rain" element={<Rain/>} />
            <Route path="thunder" element={<Thunder/>} />
            <Route path="fireplace" element={<Fireplace/>} />
            <Route path="cafe" element={<Cafe/>} />
            <Route path="nature" element={<Nature/>} />
          </Route>
        </Routes>
      </BrowserView>
      <MobileView>
          <div className="grid h-96 place-items-center">
            <p className="text-center font-bold text-gray-500 mx-4">lofi.study currently only works on computer devices (e.g. laptop, desktop). We are working on finding a solution for mobile users. Keep updated by following <a href="https://instagram.com/lofistudyapp" className="text-indigo-400 hover:text-indigo-600 transition ease-in-out duration-300">@lofistudyapp</a> on Instagram.</p>
          </div>
      </MobileView>
    </div>
  );
}

export default App;
