import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="bg-gray-200 h-screen dark:bg-gray-900 transition">
        <a href="/">
          <h3 className="font-black text-2xl z-10 text-slate-700 p-4 dark:text-slate-200 transition">lofi.study
            <span className="ml-2 text-xs text-white p-1 rounded-full font-bold bg-indigo-500 dark:text-slate-200">BETA</span>
          </h3>
        </a>
        <div className="my-24">
          <App />
        </div>
        <div className="grid place-items-center fixed inset-x-0 bottom-0 mb-2">
          <p className="font-bold text-sm text-gray-500">made by <a href="https://twitter.com/okaynils"  rel="noreferrer"  target="_blank" className="hover:text-indigo-600 dark:hover:text-indigo-600 text-indigo-400 transition ease-in-out duration-300 dark:text-slate-200">nils</a></p>
        </div>
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
