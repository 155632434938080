import Player from './Player';
import * as React from 'react'

export const PlayerController = {
    players: [
        new Player({ name: "lofi", videoId: "jfKfPfyJRdk", ref: React.createRef() }),
        new Player({ name: "jazz", videoId: "bpWMmK-OTV8", ref: React.createRef() }),
        new Player({ name: "classical", videoId: "2gO1v2GPMFk", ref: React.createRef() }),
        new Player({ name: "rain", videoId: "42M3esYyHdw", ref: React.createRef() }),
        new Player({ name: "thunder", videoId: "fkFiIhDR_nc", ref: React.createRef() }),        
        new Player({ name: "nature", videoId: "Qm846KdZN_c", ref: React.createRef() }),
        new Player({ name: "cafe", videoId: "gaGrHUekGrc", ref: React.createRef() }),
        new Player({ name: "fireplace", videoId: "6VB4bgiB0yA", ref: React.createRef() })
    ],

    getStateOfPlayer(nameOfPlayer: String) {
        return this.players.find(e => e.props.name === nameOfPlayer)?.props.ref.current
    },

    getCurrentVolumeOfPlayer(nameOfPlayer: String) {
        return this.getStateOfPlayer(nameOfPlayer).state.volume;
    },

    async changeVolumeTo(nameOfPlayer: String, volume:number) {
        var StateOfPlayerToChange = this.getStateOfPlayer(nameOfPlayer).state;
        this.players.find(e => e.props.name === nameOfPlayer)?.setVolTo(StateOfPlayerToChange, volume);
    },

    play: function(nameOfPlayer: String) {
        var refi = this.players[0].props.ref.current;
        if(refi.state.player) {
            this.players[0].swagballs(refi.state);
        }
    },

    getPlayersForDOM: function() {
        return this.players.map((player) => {return(<Player name={player.props.name} videoId={player.props.videoId} ref={player.props.ref}></Player>)})
    },
}

export default PlayerController;